/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import type { Dispatch, SetStateAction } from 'react';

import MobileMenu from './Mobile';

import style from './style.module.scss';

type NavMenuProps = {
  isDark: boolean;
  popUpHook: [boolean, Dispatch<SetStateAction<boolean>>];
};
export function NavMenu({ isDark, popUpHook }: NavMenuProps) {
  const [isPopUp, setIsPopUp] = popUpHook;

  return (
    <>
      <div className={style.menu} data-popup={isPopUp} data-dark={isDark} onClick={() => setIsPopUp(!isPopUp)} />
      {isPopUp && <MobileMenu onClick={setIsPopUp} />}
    </>
  );
}
