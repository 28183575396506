import { ContentsBox } from 'src/components/ContentsBox/index';
import { ContentsFrame } from 'src/components/ContentsFrame';
import Context from 'src/components/Context';
import { CustomFlex } from 'src/components/CustomFlex';
import Introduction from 'src/components/Introduction';
import MoreBtn from 'src/components/MoreBtn';
import PageBackground from 'src/components/PageBackground';
import Spacer from 'src/components/Spacer';
import { useTranslation } from 'src/hooks/useTransaction';

import MiniDataBox from './components/MiniDataBox';
import PartnerCard from '../../components/PartnerCard/index';

import style from './index.module.scss';

export function Validator() {
  const { t } = useTranslation();
  return (
    <PageBackground page="Validator">
      <ContentsFrame type="main">
        <Spacer space={280} mSpace={100} />
        <div data-anchor="validatorNode" />
        <Introduction titleType="h1" descType="b1" descWidth="950px" desc={t('pages.Validator.index.ValidatorNodeDesc')} gap={30} mGap={14}>
          {t('pages.Validator.index.Industry-Leading')}
          <br />
          <strong data-color="red">{t('pages.Validator.index.Validator Node')} </strong>
          {t('pages.Validator.index.Operator')}
        </Introduction>
        <Spacer space={140} mSpace={70} />
        <MiniDataBox />
        <Spacer space={180} mSpace={70} />
        <ContentsBox
          intro={
            <Introduction descType="b3600" desc={t('pages.Validator.index.validator desc')} descWidth="658px ">
              {t('pages.Validator.index.Validator')}
            </Introduction>
          }
          context={
            <CustomFlex is2x2>
              <Context
                contents={[
                  {
                    icon: `${process.env.PUBLIC_URL}/assets/icon/MONITORING.svg`,
                    title: t('pages.Validator.index.24/7 Monitoring'),
                    desc: t('pages.Validator.index.monitoring desc'),
                    maxWidth: 520,
                  },
                  {
                    icon: `${process.env.PUBLIC_URL}/assets/icon/UPTIMES.svg`,
                    title: t('pages.Validator.index.99% Uptime'),
                    desc: t('pages.Validator.index.99% uptime desc'),
                    maxWidth: 520,
                  },
                  {
                    icon: `${process.env.PUBLIC_URL}/assets/icon/MULTIPLE SENTRY NODES.svg`,
                    title: t('pages.Validator.index.Globally Distributed Nodes'),
                    desc: t('pages.Validator.index.Gloablly distribute desc'),
                    maxWidth: 520,
                  },
                  {
                    icon: `${process.env.PUBLIC_URL}/assets/icon/ON-CHAIN GOVERNANCE.svg`,
                    title: t('pages.Validator.index.On-chain Governance'),
                    desc: t('pages.Validator.index.On-chain desc'),
                    maxWidth: 520,
                  },
                ]}
              />
            </CustomFlex>
          }
        />
        <Spacer space={240} mSpace={60} />
        <PartnerCard count={6} />
        <Spacer space={40} mSpace={20} />
        <div className={style.moreBtnRight}>
          <MoreBtn isInner text={t('pages.Validator.index.Show more')} link="/about/#partnerCard" />
        </div>
        <Spacer space={240} mSpace={60} />
        <div data-anchor="deployNode" />
        <Introduction titleType="h1" descType="b1" desc={t('pages.Validator.index.deployNodeDesc')} descWidth="820px" gap={30} mGap={14}>
          {t('pages.Validator.index.Easily Deploy')} <br />
          {t('pages.Validator.index.Your Own')} <strong data-color="red">{t('pages.Validator.index.High Performant Node')}</strong>
        </Introduction>
        <Spacer space={140} mSpace={40} />
        <ContentsBox
          topPadding={92}
          bottomPadding={92}
          mBottomPadding={24}
          bgImg={<div className={style.bgImg} />}
          intro={
            <Introduction>
              {t('pages.Validator.index.For further inquiry')}
              <br />
              <strong data-color="red">{t('pages.Validator.index.contact us')}</strong>
              {t('pages.Validator.index.via email')}
            </Introduction>
          }
          context={
            <>
              <Spacer space={40} mSpace={20} />
              <MoreBtn isInner text={t('pages.Validator.index.Contact Us')} link="mailto:help@stamper.network" color="black" />
            </>
          }
        />
        <Spacer space={300} mSpace={100} />
      </ContentsFrame>
    </PageBackground>
  );
}
