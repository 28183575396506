import { useState } from 'react';

import { useTranslation } from 'src/hooks/useTransaction';

import type { menuData } from './datas';
import { Box } from '../../../Box';

import style from './index.module.scss';

export function NavItem({ title, href = '', contents, isDark }: menuData) {
  const [isHover, setHover] = useState(false);
  const { t } = useTranslation();
  return (
    <div
      className={style.container}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      <a href={href}>
        <p className={isDark ? style.white : style.dark}>{t(title)}</p>
      </a>
      {isHover && (
        <div className={style.contentsWrap}>
          <Box radius={15}>
            <div className={style.area}>
              {contents?.map((item, idx) => (
                <a className={style.item} key={idx} href={item.href}>
                  {t(item.content)}
                </a>
              ))}
            </div>
          </Box>
        </div>
      )}
    </div>
  );
}
