import { map } from 'lodash';

import MoreBtn from 'src/components/MoreBtn';

import style from './index.module.scss';

export default function Certification() {
  const certifications = [
    {
      name: 'ISO 27001',
      image: 'https://s3.ap-northeast-2.amazonaws.com/cosmostation.io/certificates/iso.png',
      link: '/pdfAssets/ISO27001.pdf',
    },
    {
      name: 'KIPO',
      image: 'https://s3.ap-northeast-2.amazonaws.com/cosmostation.io/certificates/kipo.png',
      link: '/pdfAssets/KIPO.pdf',
    },
  ];

  return (
    <div className={style.container}>
      <div className={style.title}>Certification Mark</div>
      <div className={style.certifications}>
        {map(certifications, (v) => (
          <div key={v.name} className={style.certificate}>
            <img className={style.certificateImg} src={v.image} alt="certificate" />

            <div className={style.name}>{v.name}</div>
            <MoreBtn text="View Certificates" link={v.link} />
          </div>
        ))}
      </div>
    </div>
  );
}
