import React from 'react';

import { useIsDesktop } from 'src/hooks/useIsDesktop';
import { useTranslation } from 'src/hooks/useTransaction';

import style from './index.module.scss';

type ContentsProps = { contents: ContextProps[] };
type ContextProps = {
  maxWidth?: number;
  icon: string;
  title: string;
  desc: string | React.ReactNode;
  mDesc?: React.ReactNode;

  titleMaxWidth?: number | string;
  descMaxWidth?: number;

  mTitleWidth?: number | string;
  mDescWidth?: number;
};

export default function Context({ contents }: ContentsProps) {
  const isDesktop = useIsDesktop('600px');
  const { language } = useTranslation();
  return (
    <>
      {contents.map((t: ContextProps): React.ReactNode => {
        const item = t;
        item.mDesc = item.mDesc || item.desc;
        return (
          <div className={style.container} style={{ maxWidth: isDesktop ? item.maxWidth : 'initial' }} key={item.title}>
            <img className={style.img} src={item.icon} alt={item.title} />
            <div className={style.title} style={{ maxWidth: language === 'ko' ? 'initial' : isDesktop ? item.titleMaxWidth : item.mTitleWidth }}>
              {item.title}
            </div>
            <div className={style.desc} style={{ maxWidth: isDesktop ? item.descMaxWidth : 'initial' }}>
              {isDesktop ? item.desc : item.mDesc}
            </div>
          </div>
        );
      })}
    </>
  );
}
