import { useEffect, useMemo, useRef, useState } from 'react';

export function useIntersection(options?: IntersectionObserverInit) {
  const dom = useRef<HTMLInputElement>(null);
  const [isIntersecting, setIsIntersecting] = useState(false);

  const isIntersectingMemo = useMemo(() => isIntersecting, [isIntersecting]);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting !== isIntersecting) setIsIntersecting(entry.isIntersecting);
    }, options);

    if (dom.current) {
      observer.observe(dom.current);
    }
  }, [isIntersecting, options]);

  return { ref: dom, isIntersecting: isIntersectingMemo };
}
