import { useEffect, useState } from 'react';

import { useIsDesktop } from 'src/hooks/useIsDesktop';
import { useReactPath } from 'src/hooks/useReactPath';
import { useScroll } from 'src/hooks/useScroll';

// import { useTranslation } from 'src/hooks/useTransaction';
import { NavItem } from './components/Item';
import { menuDatas } from './components/Item/datas';
import { LangaugeSelector } from './components/LangaugeSelector';
import { NavMenu } from './components/Menu';
import { ContentsFrame } from '../ContentsFrame';

import style from './index.module.scss';

export function Navigation() {
  const { isScrolled } = useScroll(100);

  // const { language } = useTranslation();

  const [isMain, setIsMain] = useState(false);
  const [isPopUp, setIsPopUp] = useState(false);
  const isDesktop = useIsDesktop();

  const path = useReactPath();
  useEffect(() => {
    if (path !== '/main' && path !== '/') {
      setIsMain(false);
    } else {
      setIsMain(true);
    }
  }, [path]);
  return (
    <div className={style.nav_section} data-dark={isPopUp ? true : !isScrolled}>
      <ContentsFrame type="nav">
        <div className={style.container}>
          <a href="/main">
            <div className={style.logo} data-dark={isPopUp ? false : isMain ? !isScrolled : false} />
          </a>

          <ul className={style.navItem}>
            {isDesktop &&
              menuDatas.map((item) => (
                <NavItem key={item.title} isDark={isMain ? !isScrolled : false} title={item.title} href={item.href} contents={item.contents} />
              ))}
            {!isPopUp && <LangaugeSelector isDesktop={isDesktop} isDark={isMain ? !isScrolled : false} />}
            {!isDesktop && <NavMenu isDark={isPopUp ? false : isMain ? !isScrolled : false} popUpHook={[isPopUp, setIsPopUp]} />}
          </ul>
        </div>
      </ContentsFrame>
    </div>
  );
}
