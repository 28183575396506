import { useEffect } from 'react';
import { BrowserRouter, Route, Routes as BaseRoutes } from 'react-router-dom';

import { Footer } from 'src/components/Footer';
import { Navigation } from 'src/components/Nav';
import { PATH } from 'src/constants/route';
import { useReactHash } from 'src/hooks/useReactHash';
import About from 'src/pages/About';
import { Main } from 'src/pages/Main';
import NotFound from 'src/pages/NotFound';
import { Product } from 'src/pages/Product';
import Service from 'src/pages/Service';
import { Validator } from 'src/pages/Validator';
import Ventures from 'src/pages/Ventures';
import { scrollTo } from 'src/utils/scrollTo';

import style from './index.module.scss';

export default function Root() {
  const hash = useReactHash();

  useEffect(() => {
    scrollTo(hash.replace('#', ''));
  }, [hash]);
  return (
    <div className={style.root} id="routeRoot">
      <div className="contents" id="rootContent">
        <Navigation />
        <div id="navPopUp" />
        <BrowserRouter>
          <BaseRoutes>
            <Route path={PATH.ROOT} element={<Main />} />
            <Route path={PATH.MAIN} element={<Main />} />
            <Route path={PATH.VALIDATOR} element={<Validator />} />
            <Route path={PATH.PRODUCT} element={<Product />} />
            <Route path={PATH.SERVICE} element={<Service />} />
            <Route path={PATH.VENTRUES} element={<Ventures />} />
            <Route path={PATH.ABOUT} element={<About />} />
            <Route path="/*" element={<NotFound />} />
          </BaseRoutes>
        </BrowserRouter>
        <Footer />
      </div>
    </div>
  );
}
