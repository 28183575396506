import 'src/i18n';

import React from 'react';
import ReactDOM from 'react-dom/client';

import Root from './Routes';

import 'src/styles/normalize.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
);
