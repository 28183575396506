import style from './index.module.scss';

interface CountElementProps {
  title: string;
  leftSymbol?: string;
  value: number | string;
  rightSymbol?: string;
  color?: string;
  titleType?: string;

  mTitleType?: string;
}

export default function CountElement({ title, leftSymbol, value, rightSymbol, color = 'white', titleType = 'b0', mTitleType = titleType }: CountElementProps) {
  return (
    <div className={style.wrapper} data-color={color}>
      <div className={style.title} data-title={titleType} data-mtitle={mTitleType}>
        {title}
      </div>
      <div className={style.numValue}>
        {leftSymbol}
        {value}
        {rightSymbol}
      </div>
    </div>
  );
}
