import React from 'react';

import style from './index.module.scss';

interface BoxProps {
  children: React.ReactNode;
  radius?: number;
  mRadius?: number;
  color?: string;
}

export function Box({ children, radius = 50, mRadius = radius / 2, color = 'white' }: BoxProps) {
  return (
    <div className={style.borderBox} data-radius={radius} data-mradius={mRadius} data-color={color}>
      {children}
    </div>
  );
}
