import { useMemo } from 'react';

import { useTranslation } from 'src/hooks/useTransaction';

import { ContactItem } from './components/ContactItem';
import { FooterItem } from './components/MenuItem';
import { ContentsFrame } from '../ContentsFrame';
import { menuDatas } from '../Nav/components/Item/datas';

import style from './index.module.scss';

export function Footer() {
  const { t, language } = useTranslation();

  const serviceUrl = useMemo(() => {
    if (language === 'en') {
      return 'https://www.cosmostation.io/service_en?target=privacy';
    }
    if (language === 'ko') {
      return 'https://www.cosmostation.io/service_privacy_policy_kr?target=privacy';
    }
    return 'https://www.cosmostation.io/service_privacy_policy_kr?target=privacy';
  }, [language]);

  return (
    <div className={style.area}>
      <ContentsFrame type="footer">
        <div className={style.wrapper}>
          <div className={style.topWrapper}>
            <img className={style.img} src={`${process.env.PUBLIC_URL}/assets/logo/logo_white.svg`} alt="STAMPER LOGO" />
            <div className={style.itemWrapper}>
              {menuDatas.map((item, idx) => (
                <FooterItem title={item.title} contents={item.contents} key={idx} />
              ))}
            </div>
          </div>
          <div className={style.bottomWrapper}>
            <div className={style.copyright}>{t('Footer.copyright')}</div>
            <div className={style.serviceFooter}>
              <a className={style.privacyLink} href={serviceUrl} target="_blank" rel="noreferrer">
                {t('Footer.privacy')}
              </a>
              <ContactItem />
            </div>
          </div>
        </div>
      </ContentsFrame>
    </div>
  );
}
