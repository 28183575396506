export function scrollTo(hash: string) {
  if (hash.length === 0) return;
  const root = document.getElementById('routeRoot');
  const rootContents = document.getElementById('rootContent');

  const query = `[data-anchor=${hash}]`;
  const target = document.querySelector(query);

  if (root && target && rootContents) {
    const stdRect = rootContents.getBoundingClientRect();
    const targetRect = target.getBoundingClientRect();
    const targetTop = targetRect.top - stdRect.top;

    // FOR TEST
    // const scrollHeight = Math.max(rootContents.scrollHeight, rootContents.offsetHeight, rootContents.clientHeight);
    // console.log(scrollHeight);
    // target.scrollIntoView({ behavior: 'smooth' });
    root.scrollTo({
      top: targetTop - (window.innerWidth <= 600 ? 120 : 200),
      behavior: 'smooth',
    });
  }
}
