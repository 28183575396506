import Introduction from 'src/components/Introduction';
import Spacer from 'src/components/Spacer';

import { useTranslation } from '../../../../hooks/useTransaction';
import { ImgBox } from '../ImgBox';
import { StoreBtn } from '../StoreBtn';

import style from './index.module.scss';

export function MobileWallet() {
  const { t } = useTranslation();
  return (
    <ImgBox
      text={
        <div className={style.text}>
          <Introduction titleType="h1" descType="b1" desc={t('pages.Product.MobileWallet.Mobile Wallet desc')} descWidth="540px">
            {t('pages.Product.MobileWallet.Mobile Wallet')}
          </Introduction>
          <Spacer space={80} mSpace={40} />
          <div className={style.btnWrapper}>
            <StoreBtn
              href="https://play.google.com/store/apps/details?id=wannabit.io.cosmostaion"
              img={`${process.env.PUBLIC_URL}/assets/product/btn/playstore.svg`}
              alt="GOOGLE PLAYSTORE"
            />
            <StoreBtn
              href="https://apps.apple.com/kr/app/cosmostation/id1459830339"
              img={`${process.env.PUBLIC_URL}/assets/product/btn/appstore.svg`}
              alt="APPLE APPSTORE"
            />
          </div>
        </div>
      }
      img={<img className={style.img} src={`${process.env.PUBLIC_URL}/assets/product/mobile.png`} alt="" />}
    />
  );
}
