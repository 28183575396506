import type { menuData } from 'src/components/Nav/components/Item/datas';
import { useTranslation } from 'src/hooks/useTransaction';

import style from './index.module.scss';

export function FooterItem({ title, contents }: menuData) {
  const { t } = useTranslation();
  return (
    <div className={style.item}>
      <div className={style.title}>{t(title)}</div>
      <div className={style.contents}>
        {contents?.map((item, idx) => (
          <a href={item.href} key={idx}>
            <div className={style.contentItem}>{t(item.content)}</div>
          </a>
        ))}
      </div>
    </div>
  );
}
