import type { PageType } from 'src/types/common';

import style from './index.module.scss';

type PageBackgroundProps = {
  page: PageType;
  children: React.ReactNode;
};

export default function PageBackground({ children, page }: PageBackgroundProps) {
  return (
    <div className={style.mainSection} data-page={page}>
      {children}
    </div>
  );
}
