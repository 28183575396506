import Introduction from 'src/components/Introduction';
import { useIsDesktop } from 'src/hooks/useIsDesktop';
import { useTranslation } from 'src/hooks/useTransaction';

import style from './index.module.scss';

export default function VideoBox() {
  const { t } = useTranslation();
  const isDesktop = useIsDesktop('600px');

  return (
    <div className={style.videoContainer}>
      {isDesktop ? (
        <video
          key={`${process.env.PUBLIC_URL}/assets/video/MAIN.png`}
          preload="none"
          poster={`${process.env.PUBLIC_URL}/assets/video/MAIN.png`}
          className={style.video}
          loop
          muted
          autoPlay
          playsInline
        >
          <source src={`${process.env.PUBLIC_URL}/assets/video/stamper.mp4`} type="video/mp4" />
        </video>
      ) : (
        <video
          key={`${process.env.PUBLIC_URL}/assets/video/MOBILE.png`}
          preload="none"
          poster={`${process.env.PUBLIC_URL}/assets/video/MOBILE.png`}
          className={style.video}
          loop
          muted
          autoPlay
          playsInline
        >
          <source src={`${process.env.PUBLIC_URL}/assets/video/mstamper.mp4`} type="video/mp4" />
        </video>
      )}
      <div className={style.intro}>
        <Introduction color="white" desc={t('pages.Main.VideoBox.desc')} descType="b3" titleType="h1" mTitleType="h0" gap={30} descWidth="50%" rPadding="20px">
          <strong data-color="red"> {t('pages.Main.VideoBox.enterpriseLevel')} </strong>
          <br />
          {t('pages.Main.VideoBox.blockChain')}
          <br />
          {t('pages.Main.VideoBox.infrastructure')}
        </Introduction>
      </div>
    </div>
  );
}
