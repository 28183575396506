const base_path = 'https://s3.ap-northeast-2.amazonaws.com/cosmostation.io/partners/';

export type PartnerDataProps = {
  partner: string;
  href: string;
};
const cards: PartnerDataProps[] = [
  { partner: 'icf', href: 'https://interchain.io/' },
  { partner: 'ignite', href: 'https://ignite.com/' },
  { partner: 'crypto-com', href: 'https://crypto.com/' },
  { partner: 'crypto-org', href: 'https://crypto.org/' },
  { partner: 'cronos', href: 'https://cronos.org/' },
  { partner: 'binance_x', href: 'https://binancex.dev/' },
  { partner: 'binance', href: 'https://www.binance.com' },
  { partner: 'okx', href: 'https://www.okx.com/' },
  { partner: 'okc', href: 'https://www.okx.com/okc' },
  { partner: 'chainlink', href: 'https://chain.link/' },
  { partner: 'osmosis', href: 'https://osmosis.zone/' },
  { partner: 'figment', href: 'https://www.figment.io/' },
  { partner: 'coingecko', href: 'https://www.coingecko.com/' },
  { partner: 'moonpay', href: 'https://www.moonpay.com/' },
  { partner: 'stargaze', href: 'https://www.stargaze.zone/' },
  { partner: 'gravitybridge', href: 'https://www.gravitybridge.net/' },
  { partner: 'axelar', href: 'https://axelar.network/' },
  { partner: 'kava', href: 'https://www.kava.io/' },
  { partner: 'trustwallet', href: 'https://trustwallet.com/' },
  { partner: 'emoney', href: 'https://e-money.com/' },
  { partner: 'forbole', href: 'https://www.forbole.com/' },
  { partner: 'band', href: 'https://bandprotocol.com/' },
  { partner: 'persistence', href: 'https://persistence.one/' },
  { partner: 'iris', href: 'https://www.irisnet.org/' },
  { partner: 'gdac', href: 'https://www.gdac.com/' },
];
const prevCards: PartnerDataProps[] = [
  { partner: 'icf', href: 'https://interchain.io/' },
  { partner: 'ignite', href: 'https://ignite.com/' },

  { partner: 'crypto-com', href: 'https://crypto.com/' },
  { partner: 'binance', href: 'https://www.binance.com' },
  { partner: 'okx', href: 'https://www.okx.com/' },
  { partner: 'chainlink', href: 'https://chain.link/' },
];

export const partnerDatas = cards.map<PartnerDataProps>((card: PartnerDataProps) => ({ partner: `${base_path + card.partner}.png`, href: card.href }));
export const prevPartnerDatas = prevCards.map<PartnerDataProps>((card: PartnerDataProps) => ({ partner: `${base_path + card.partner}.png`, href: card.href }));
