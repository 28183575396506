import { useEffect, useState } from 'react';

export function useCountUp(start = 1, end = 100, lerp = 0.15) {
  const [count, setCount] = useState(start);

  useEffect(() => {
    let currentNumber = start;
    const counter = setInterval(() => {
      currentNumber += (end - currentNumber) * lerp + 1;
      setCount(currentNumber);

      if (currentNumber >= end) {
        clearInterval(counter);
      }
    }, 30);
    return () => clearInterval(counter);
  }, [start, end, lerp]);

  return Math.floor(count).toLocaleString();
}
