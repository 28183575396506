import type { AlignmentType } from 'src/types/common';

import { Box } from '../Box';

import style from './index.module.scss';

type CardProps = {
  href?: string;
  icon: string;
  width?: number;
  mWidth?: number;
  radius?: number;
  mRadius?: number;
  color?: string;
  content?: React.ReactNode;
  tPadding?: number;
  alignment?: AlignmentType;
};

export default function Card({ href, alignment = 'center', tPadding, icon, width, mWidth, radius, mRadius, color, content }: CardProps) {
  return (
    <Box color={color} radius={radius} mRadius={mRadius}>
      {href ? (
        <a className={style.card} href={href} data-toppadding={tPadding} target="_blank" rel="noreferrer">
          <div className={style.background} data-align={alignment} data-size={width} data-msize={mWidth} style={{ backgroundImage: `url('${icon}')` }} />
          {content && content}
        </a>
      ) : (
        <div className={style.card} data-toppadding={tPadding}>
          <div className={style.background} data-align={alignment} data-size={width} data-msize={mWidth} style={{ backgroundImage: `url('${icon}')` }} />
          {content && content}
        </div>
      )}
    </Box>
  );
}
