import { Box } from 'src/components/Box';
import Introduction from 'src/components/Introduction';
import MoreBtn from 'src/components/MoreBtn';
import { useTranslation } from 'src/hooks/useTransaction';

import Spacer from '../../../../components/Spacer/index';

import style from './index.module.scss';

export default function IntroBox() {
  const { t } = useTranslation();
  return (
    <>
      <div className={style.introContainer}>
        <div className={style.area}>
          <Box radius={50} mRadius={25}>
            <div className={style.intro} data-background="atom">
              <Introduction titleType="h3">
                {t('pages.Main.IntroBox.Industry-Leading')}
                <br />
                {t('pages.Main.IntroBox.Blockchain')}
                <br />
                <strong data-color="red">{t('pages.Main.IntroBox.R&D')}</strong> {t('pages.Main.IntroBox.and')}{' '}
                <strong data-color="red">{t('pages.Main.IntroBox.Consulting')}</strong>
                <br />
                {t('pages.Main.IntroBox.Services')}
              </Introduction>
              <Spacer space={30} mSpace={20} />
              <MoreBtn isInner text={t('pages.Main.IntroBox.Show more')} link="/service" />
            </div>
          </Box>
        </div>
        <div className={style.area}>
          <Box radius={50} mRadius={25}>
            <div className={style.intro} data-background="cube">
              <Introduction titleType="h3">
                {t('pages.Main.IntroBox.We back the')} <strong data-color="red">{t('pages.Main.IntroBox.next')}</strong>
                <br />
                <strong data-color="red">{t('pages.Main.IntroBox.generation of builders')} </strong>
                <br />
                {t('pages.Main.IntroBox.in the blockchain')}
                <br />
                {t('pages.Main.IntroBox.economy')}
              </Introduction>
              <Spacer space={30} mSpace={20} />
              <MoreBtn isInner text={t('pages.Main.IntroBox.Show more')} link="/ventures" />
            </div>
          </Box>
        </div>
      </div>

      <Spacer space={40} mSpace={20} />

      <Box radius={50} mRadius={25}>
        <div className={style.intro}>
          <Introduction
            titleType="h3"
            rPadding="11px"
            mChildren={
              <>
                {t('pages.Main.IntroBox.Industry leading')}
                <strong data-color="red">{` ${t('pages.Main.IntroBox.Web3 infrastructure')}`}</strong>
                {`${t('pages.Main.IntroBox.and')} `}
                <strong data-color="red">{t('pages.Main.IntroBox.end-user product suite')}</strong>
                {`${t('pages.Main.IntroBox.providing access to')}`}
                {`${t('pages.Main.IntroBox.cutting-edge blockchain protocols')}`}
              </>
            }
          >
            {t('pages.Main.IntroBox.Industry leading')}
            <strong data-color="red">{t('pages.Main.IntroBox.Web3 infrastructure')}</strong>
            {`${t('pages.Main.IntroBox.and')}`}
            <br />
            <strong data-color="red">{t('pages.Main.IntroBox.end-user product suite')}</strong>
            {t('pages.Main.IntroBox.providing access to')}
            <br />
            {t('pages.Main.IntroBox.cutting-edge blockchain protocols')}
          </Introduction>
          <Spacer space={30} mSpace={20} />
          <MoreBtn isInner text={t('pages.Main.IntroBox.Show more')} link="/product" />
        </div>
      </Box>
    </>
  );
}
