/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import type { Dispatch, SetStateAction } from 'react';
import { Fragment, useState } from 'react';

import { useTranslation } from 'src/hooks/useTransaction';
import type { PageType } from 'src/types/common';

import { menuDatas } from '../../../Item/datas';

import style from './index.module.scss';

type MobileMenuItem = { onClick: Dispatch<SetStateAction<boolean>> };

export default function MobileMenuItem({ onClick }: MobileMenuItem) {
  const { t } = useTranslation();
  const [isDropped, setIsDropped] = useState<PageType>('None');
  return (
    <div className={style.menuContainer}>
      {menuDatas.map((item) => (
        <Fragment key={item.title}>
          <div className={style.menuTitle}>
            <a onClick={() => onClick(false)} href={item.href} className={style.title}>
              {t(item.title)}
            </a>
            <div
              className={style.dropdown}
              data-isdropped={isDropped === item.title}
              onClick={() => {
                if (item.title === isDropped) {
                  setIsDropped('None');
                } else {
                  setIsDropped(item.title as PageType);
                }
              }}
            />
          </div>
          {isDropped === item.title && (
            <div className={style.menuItemContainer}>
              {item.contents?.map((itemContent) => (
                <a onClick={() => onClick(false)} className={style.menuItem} href={itemContent.href} key={itemContent.content}>
                  {t(itemContent.content)}
                </a>
              ))}
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
}
