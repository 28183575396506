/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react';

import { useTranslation } from 'src/hooks/useTransaction';

import { Box } from '../../../Box/index';

import style from './index.module.scss';

interface LangaugeSelectorProps {
  isDesktop: boolean;
  isDark: boolean;
}
export function LangaugeSelector({ isDesktop, isDark }: LangaugeSelectorProps) {
  const [isHover, setHover] = useState(false);
  const { changeLanguage, t } = useTranslation();
  return (
    <li
      className={style.container}
      data-dark={isDark}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      <div className={style.global} data-dark={isDark} />
      <p className={style.title} data-dark={isDark} data-desktop={isDesktop}>
        {t('Nav.LanguageSelector.lang')}
      </p>
      <div className={style.arrow} data-dark={isDark} />
      {isHover && (
        <div className={style.contentsWrap} data-desktop={isDesktop}>
          <Box radius={15}>
            <div className={style.area} data-desktop={isDesktop}>
              <div
                className={style.item}
                onClick={() => {
                  changeLanguage('en');
                  if (!isDesktop) {
                    setHover(false);
                  }
                }}
              >
                <p>EN</p>
              </div>
              <div
                className={style.item}
                onClick={() => {
                  changeLanguage('ko');
                  if (!isDesktop) {
                    setHover(false);
                  }
                }}
              >
                <p>KR</p>
              </div>
            </div>
          </Box>
        </div>
      )}
    </li>
  );
}
