import type { Dispatch, SetStateAction } from 'react';
import { useEffect } from 'react';
import { createPortal } from 'react-dom';

import { ContactItem } from 'src/components/Footer/components/ContactItem';

import MobileMenuItem from './Item';

import style from './index.module.scss';

type MobileMenuProps = { onClick: Dispatch<SetStateAction<boolean>> };

export default function MobileMenu({ onClick }: MobileMenuProps) {
  useEffect(() => {
    const root = document.getElementById('routeRoot');
    if (root) {
      root.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: hidden;
      width: 100%;`;

      return () => {
        const scrollY = document.body.style.top;
        root.style.cssText = '';
        window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
      };
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, []);
  return createPortal(
    <div className={style.mobileMenu}>
      <MobileMenuItem onClick={onClick} />
      <ContactItem isNav />
    </div>,
    document.getElementById('navPopUp') as HTMLElement,
  );
}
