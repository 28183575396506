export type menuData = {
  title: string;
  href?: string;
  isDark?: boolean;
  contents?: contnetsData[];
};

type contnetsData = {
  content: string;
  href: string;
};

export const menuDatas: menuData[] = [
  {
    title: 'Nav.Item.Validator',
    href: '/validator',

    contents: [
      {
        content: 'Nav.Item.Validator Node',
        href: '/validator/#validatorNode',
      },
      { content: 'Nav.Item.Deploy your own node', href: '/validator/#deployNode' },
    ],
  },
  {
    title: 'Nav.Item.Products',
    href: '/product',
    contents: [
      { content: 'Nav.Item.Mintscan explorer', href: '/product/#mintscanExplorer' },
      { content: 'Nav.Item.Extension wallet', href: '/product/#extensionWallet' },
      { content: 'Nav.Item.Mobile wallet', href: '/product/#mobileWallet' },
    ],
  },
  {
    title: 'Nav.Item.Services',
    href: '/service',
    contents: [
      { content: 'Nav.Item.Consulting', href: '/service/#consulting' },
      { content: 'Nav.Item.R&D', href: '/service/#rnd' },
    ],
  },
  {
    title: 'Nav.Item.Ventures',
    href: '/ventures',
    contents: [{ content: 'Nav.Item.Portfolio', href: '/ventures/#portfolioCard' }],
  },
  {
    title: 'Nav.Item.About',
    href: '/about',
    contents: [
      { content: 'Nav.Item.What we do', href: '/about/#whatWeDo' },
      { content: 'Nav.Item.Contact Us', href: '/about/#contactUs' },
    ],
  },
];
