import Card from 'src/components/Card';
import { useTranslation } from 'src/hooks/useTransaction';

import type { PartnerDataProps } from './datas';
import { partnerDatas, prevPartnerDatas } from './datas';

import style from './index.module.scss';

type PartnerCardProps = {
  count?: number;
};
export default function PartnerCard({ count = -1 }: PartnerCardProps) {
  const { t } = useTranslation();
  return (
    <div data-anchor="partnerCard">
      <div className={style.title}>{t('PartnerCard.Our Partners')}</div>
      <div className={style.cardContainer}>
        {count === -1
          ? partnerDatas.map((item: PartnerDataProps) => (
              <div className={style.card} key={item.partner}>
                <Card color="white" href={item.href} icon={item.partner} radius={30} mRadius={15} />
              </div>
            ))
          : prevPartnerDatas.slice(0, count).map((item: PartnerDataProps) => (
              <div className={style.card} key={item.partner}>
                <Card href={item.href} icon={item.partner} radius={30} mRadius={15} />
              </div>
            ))}
      </div>
    </div>
  );
}
