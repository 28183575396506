import style from './index.module.scss';

interface ImgBoxProps {
  text: React.ReactNode;
  img: React.ReactNode;
}

export function ImgBox({ text, img }: ImgBoxProps) {
  return (
    <div className={style.wrapper}>
      {text}
      <div className={style.imgArea}>{img}</div>
    </div>
  );
}
