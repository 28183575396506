import Card from 'src/components/Card';

import type { TeamCardProps } from './datas';
import { TeamCardDatas } from './datas';

import style from './index.module.scss';

export default function TeamCard() {
  const base_path = 'https://s3.ap-northeast-2.amazonaws.com/cosmostation.io/about/teams';

  return (
    <>
      <div className={style.title}>Team</div>
      <div className={style.cardContainer}>
        {TeamCardDatas.map((item: TeamCardProps) => (
          <div className={style.card} key={item.name}>
            <Card
              href={item.href}
              icon={`${base_path}/${item.id}.png`}
              color="white"
              width={140}
              mWidth={88}
              tPadding={20}
              radius={20}
              mRadius={20}
              content={
                <div className={style.contents}>
                  <div className={style.name}>{item.name}</div>
                  <div className={style.job}>
                    {item.job[0]}
                    {item.job[1] && (
                      <>
                        <br />
                        {item.job[1]}
                      </>
                    )}
                  </div>
                </div>
              }
            />
          </div>
        ))}
      </div>
    </>
  );
}
