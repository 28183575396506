import Introduction from 'src/components/Introduction';
import MoreBtn from 'src/components/MoreBtn';
import Spacer from 'src/components/Spacer';
import useImageInterval from 'src/hooks/useImageInterval';
import { useTranslation } from 'src/hooks/useTransaction';

import { ImgBox } from '../ImgBox';

import style from './index.module.scss';

export function Mintscan() {
  const { t } = useTranslation();
  const { image } = useImageInterval([`${process.env.PUBLIC_URL}/assets/product/mintscan_dash.png`], 5000);

  return (
    <ImgBox
      text={
        <div className={style.textWrapper}>
          <Introduction gap={24} mGap={10} titleType="h1" descType="b1" desc={t('pages.Product.Mintscan.Mintscan desc')} descMinwidth={423}>
            {t('pages.Product.Mintscan.Mintscan')}
          </Introduction>
          <Spacer space={60} mSpace={20} />
          <MoreBtn text={t('pages.Product.Mintscan.Get Started')} link="https://hub.mintscan.io/" />
          <Spacer space={60} mSpace={20} />
        </div>
      }
      img={<img className={style.img} src={image} alt="" />}
    />
  );
}
