import type { ReactNode } from 'react';
import React from 'react';

import { useIsDesktop } from 'src/hooks/useIsDesktop';

import style from './index.module.scss';

type TitleProps = {
  id?: string;
  color?: string;

  titleType?: string;
  mTitleType?: string;
  children: React.ReactNode;
  mChildren?: React.ReactNode;
  mTitleWidth?: number | string;

  descType?: string;
  desc?: ReactNode;
  mDesc?: ReactNode;
  descWidth?: number | string;

  descMinwidth?: number;

  gap?: number;
  mGap?: number;

  isCenter?: boolean;
  rPadding?: string;
};

export default function Introduction({
  color = 'black',
  titleType = 'h2',
  mTitleType = titleType,
  children,
  mChildren = children,
  mTitleWidth = 'initial',
  descType = 'b3',
  desc,
  mDesc = desc,
  id,
  descWidth = '60px',
  descMinwidth,
  gap = 20,
  mGap = gap - 10,
  isCenter = false,
  rPadding,
}: TitleProps) {
  const isDesktop = useIsDesktop('600px');
  const isNotmobile = useIsDesktop('470px');

  return (
    <div
      className={style.wrapper}
      data-color={color}
      data-align={isCenter}
      id={id || ''}
      style={{
        paddingRight: rPadding,
      }}
    >
      <div className={style.title} data-title={isDesktop ? titleType : mTitleType} style={{ maxWidth: isNotmobile ? 'initial' : mTitleWidth }}>
        {isDesktop ? children : mChildren}
      </div>
      {desc && (
        <div
          style={{
            paddingTop: isDesktop ? gap : mGap,
            maxWidth: isDesktop ? descWidth : 'initial',
          }}
          className={style.desc}
          data-desc={descType}
          data-mindesc={descMinwidth}
        >
          {isDesktop ? desc : mDesc}
        </div>
      )}
    </div>
  );
}
