import { ContentsFrame } from 'src/components/ContentsFrame';
import { CustomFlex } from 'src/components/CustomFlex';
import Introduction from 'src/components/Introduction';
import PageBackground from 'src/components/PageBackground';
import { useTranslation } from 'src/hooks/useTransaction';

import PortfolioCard from './components/PortfolioCard';
import { ContentsBox } from '../../components/ContentsBox';
import Context from '../../components/Context/index';
import Spacer from '../../components/Spacer/index';

export default function Ventures() {
  const { t, language } = useTranslation();
  return (
    <PageBackground page="Ventures">
      <ContentsFrame type="main">
        <Spacer space={280} mSpace={100} />
        <Introduction color="black" titleType="h1">
          <strong data-color="red">{t('pages.Ventures.index.Building')} </strong>
          {t('pages.Ventures.index.The Future')}
          <br />
          {t('pages.Ventures.index.Leaders of Web3')}
        </Introduction>
        <Spacer space={140} mSpace={60} />
        <ContentsBox
          rightPadding={38}
          intro={
            <Introduction
              color="black"
              titleType="h2"
              rPadding="10px"
              mChildren={
                language === 'ko' ? (
                  <>
                    {t('pages.Ventures.index.Venture Capital,')}
                    <br />
                    {t('pages.Ventures.index.Builder,')}
                    <br />
                    {t('pages.Ventures.index.and Contributor')}
                  </>
                ) : (
                  <>
                    {t('pages.Ventures.index.Venture Capital,')}
                    {t('pages.Ventures.index.Builder,')}
                    <br />
                    {t('pages.Ventures.index.and Contributor')}
                  </>
                )
              }
            >
              {t('pages.Ventures.index.Venture Capital,')}
              <br />
              {t('pages.Ventures.index.Builder, and Contributor')}
            </Introduction>
          }
          context={
            <CustomFlex>
              <Context
                contents={[
                  {
                    icon: `${process.env.PUBLIC_URL}/assets/icon/WEB3 PIONEERS.svg`,
                    title: t('pages.Ventures.index.Web3 Pioneers'),
                    desc: t('pages.Ventures.index.Web3 Pioneers desc'),
                    maxWidth: 305,
                  },
                  {
                    icon: `${process.env.PUBLIC_URL}/assets/icon/LONG-TERM SUPPORTER.svg`,
                    title: t('pages.Ventures.index.Long-term Supporter'),
                    desc: t('pages.Ventures.index.Long-term Supporter desc'),
                    maxWidth: 322,
                  },
                  {
                    icon: `${process.env.PUBLIC_URL}/assets/icon/INFRASTRUCTURE PROVIDER.svg`,
                    title: t('pages.Ventures.index.Infrastructure Provider'),
                    desc: t('pages.Ventures.index.Infrastructure Provider desc'),
                    maxWidth: 342,
                  },
                ]}
              />
            </CustomFlex>
          }
        />
        <Spacer space={240} mSpace={60} />
        <PortfolioCard />
        <Spacer space={300} mSpace={100} />
      </ContentsFrame>
    </PageBackground>
  );
}
