import Introduction from 'src/components/Introduction';
import MoreBtn from 'src/components/MoreBtn';
import Spacer from 'src/components/Spacer';
import { useTranslation } from 'src/hooks/useTransaction';

import style from './index.module.scss';

export function Spacestation() {
  const { t } = useTranslation();
  return (
    <div className={style.container} style={{ display: 'none' }}>
      <div className={style.titleWrapper}>
        <Introduction titleType="h1" descType="b1" descWidth={907} desc={t('pages.Product.Spacestation.interchainBridge desc')} isCenter>
          {t('pages.Product.Spacestation.spacestation.zone')}
        </Introduction>
        <Spacer space={56} mSpace={20} />
        <MoreBtn text={t('pages.Product.Spacestation.Get Started')} link="https://spacestation.zone/" />
      </div>
      <Spacer space={118} mSpace={47} />
      <img className={style.img} src={`${process.env.PUBLIC_URL}/assets/product/spacestation.png`} alt="" />
    </div>
  );
}
