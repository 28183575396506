import { useEffect, useState } from 'react';

import { Box } from 'src/components/Box';
import Introduction from 'src/components/Introduction';
import MoreBtn from 'src/components/MoreBtn';
import Spacer from 'src/components/Spacer';
import { useCountUp } from 'src/hooks/useCountUp';
import { useIntersection } from 'src/hooks/useIntersection';
import { useTranslation } from 'src/hooks/useTransaction';

import CountElement from '../../../../components/CountElement';
import { useGetStackingValue } from '../../../../hooks/useGetStackingValue';

import style from './index.module.scss';

export default function DataBox() {
  const { t } = useTranslation();
  const { ref, isIntersecting } = useIntersection({ threshold: 0.2 });
  const [num, setString] = useState(0);

  const numString = useCountUp(0, num, 0.4);
  const { count } = useGetStackingValue();
  useEffect(() => {
    if (isIntersecting) {
      setString(count);
    }
  }, [isIntersecting, count]);

  return (
    <Box color="black" radius={50} mRadius={25}>
      <div className={style.dataBox}>
        <div className={style.topWrapper}>
          <Introduction color="white">
            <strong data-color="red">{t('pages.Main.DataBox.Trusted')}</strong> {t('pages.Main.DataBox.Validator')} <br />
            {t('pages.Main.DataBox.& Dedicated Contributor')}
          </Introduction>
          <Spacer space={30} mSpace={20} />
          <MoreBtn isInner text={t('pages.Main.DataBox.Show more')} link="/validator" color="white" />
        </div>
        <div className={style.bottomWrapper} ref={ref}>
          <div className={style.counter}>
            <CountElement title={t('pages.Main.DataBox.Total asset staked')} leftSymbol="$" value={numString} />
          </div>
          <div className={style.counter}>
            <CountElement title={t('pages.Main.DataBox.Total networks')} value={50} rightSymbol="+" />
          </div>
          <div className={style.counter}>
            <CountElement title={t('pages.Main.DataBox.Uptime')} value={99.9} rightSymbol="%" />{' '}
          </div>
        </div>
      </div>
    </Box>
  );
}
