import { useEffect, useState } from 'react';

export function useScroll(yVal: number) {
  const [state, setState] = useState({
    x: 0,
    y: 0,
  });
  const [isScrolled, setIsScrolled] = useState(false);
  const onScroll = () => {
    const root = document.getElementById('routeRoot');

    if (root) {
      setState({ x: root.scrollLeft, y: root.scrollTop });
    }
  };
  useEffect(() => {
    const root = document.getElementById('routeRoot');
    if (root) {
      root.addEventListener('scroll', onScroll);
    }
    return () => root?.removeEventListener('scroll', onScroll);
  }, []);
  useEffect(() => {
    if (state.y > yVal) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  }, [state.y, yVal]);
  return { isScrolled };
}
