import style from './index.module.scss';

interface StoreBtnProps {
  href: string;
  img: string;
  alt: string;
}

export function StoreBtn({ href, img, alt }: StoreBtnProps) {
  return (
    <a href={href} target="_blank" rel="noreferrer">
      <div className={style.btn}>
        <img src={img} alt={alt} />
      </div>
    </a>
  );
}
