import { useIsDesktop } from '../../hooks/useIsDesktop';

import style from './index.module.scss';

interface SpacerProps {
  space: number;
  mSpace: number;
}

export default function Spacer({ space, mSpace }: SpacerProps) {
  const isDesktop = useIsDesktop();
  return (
    <div
      className={style.space}
      style={{
        height: isDesktop ? space : mSpace,
      }}
    />
  );
}
