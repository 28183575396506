import React from 'react';

import { CONTENT_TYPE } from 'src/constants/common';
import type { contentType } from 'src/types/common';

import style from './index.module.scss';

interface ContentsFrameProps {
  type?: contentType;
  children: React.ReactNode;
}
export function ContentsFrame({ type = CONTENT_TYPE.MAIN, children }: ContentsFrameProps) {
  return type === CONTENT_TYPE.MAIN ? (
    <div className={style.overFlower}>
      <div className={style.contentsFrame} data-screen={type}>
        {children}
      </div>
    </div>
  ) : (
    <div className={style.contentsFrame} data-screen={type}>
      {children}
    </div>
  );
}
