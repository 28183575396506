export const LANGUAGE_TYPE = {
  KR: 'ko',
  EN: 'en',
} as const;

export const CONTENT_TYPE = {
  FULLSCREEN: 'fullScreen',
  WIDESCREEN: 'wideScreen',
  MAIN: 'main',
  NAV: 'nav',
  VIDEO: 'video',
  FOOTER: 'footer',
} as const;

export const PAGE = {
  NONE: 'None',
  VALIDATOR: 'Validator',
  PRODUCT: 'Product',
  SERVICE: 'Service',
  VENTURES: 'Ventures',
  ABOUT: 'About',
} as const;
export const ALIGNMENT = {
  CENTER: 'center',
  START: 'start',
  END: 'end',
} as const;
