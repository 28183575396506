import React from 'react';

import style from './index.module.scss';

interface CustomFlexProps {
  children: React.ReactNode;
  is2x2?: boolean;
}

export function CustomFlex({ children, is2x2 = false }: CustomFlexProps) {
  return (
    <div className={style.frame} data-is2x2={is2x2}>
      {children}
    </div>
  );
}
