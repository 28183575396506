import { useIsDesktop } from '../../../../hooks/useIsDesktop';

import style from './index.module.scss';

type ContentsProps = {
  title: string;
  contents: string;
};
type HrefContentsProps = {
  contents: ContentsProps[];
};

export default function RnDBox({ contents }: HrefContentsProps) {
  const isDesktop = useIsDesktop('600px');
  const radius = isDesktop ? 18 : 12;
  return (
    <div className={style.HrefContainer}>
      {contents.map((item) => (
        <div className={style.HrefContent} key={item.title}>
          <div className={style.topWrapper}>
            <svg
              className={style.link}
              width={radius * 2}
              height={radius * 2}
              viewBox={`0 0 ${radius * 2} ${radius * 2}`}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx={radius} cy={radius} r={radius - 0.5} stroke="#DB2222" strokeDasharray="1 3" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d={
                  isDesktop
                    ? 'M20.7919 12.2282C20.4876 11.9239 19.9942 11.9239 19.6899 12.2282C19.3856 12.5325 19.3856 13.0259 19.6899 13.3302L23.3395 16.9799L10.7792 16.9799C10.3489 16.9799 10 17.3287 10 17.7591C10 18.1895 10.3489 18.5383 10.7792 18.5383L23.3395 18.5383L19.6899 22.1879C19.3856 22.4923 19.3856 22.9856 19.6899 23.29C19.9942 23.5943 20.4876 23.5943 20.7919 23.29L25.7718 18.3101C26.0761 18.0058 26.0761 17.5124 25.7718 17.2081L20.7919 12.2282Z'
                    : 'M13.8611 8.15215C13.6582 7.94928 13.3293 7.94928 13.1264 8.15215C12.9236 8.35503 12.9236 8.68395 13.1264 8.88683L15.5595 11.3199L7.186 11.3199C6.89909 11.3199 6.6665 11.5525 6.6665 11.8394C6.6665 12.1263 6.89909 12.3589 7.186 12.3589L15.5595 12.3589L13.1264 14.792C12.9236 14.9948 12.9236 15.3238 13.1264 15.5266C13.3293 15.7295 13.6582 15.7295 13.8611 15.5266L17.181 12.2067C17.3839 12.0039 17.3839 11.6749 17.181 11.4721L13.8611 8.15215Z'
                }
                fill="#DB2222"
              />
            </svg>
            <div className={style.title}>{item.title}</div>
          </div>
          <div className={style.contens}>{item.contents}</div>
        </div>
      ))}
    </div>
  );
}
