import { useCallback, useEffect, useRef, useState } from 'react';

export default function useImageInterval(images: string[], interval = 5000) {
  const [image, setImage] = useState('');
  const [index, setIndex] = useState(0);

  const callback: () => unknown = useCallback(() => {
    let nextIdx = index + 1;
    if (images.length <= nextIdx) {
      nextIdx = 0;
    }
    setIndex(nextIdx);
  }, [images, index]);

  const savedCallback = useRef(callback);

  useEffect(() => {
    setImage(images[index]);
  }, [images, index]);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (interval == null) {
      return;
    }

    const timeId = setInterval(() => savedCallback.current(), interval);

    // eslint-disable-next-line consistent-return
    return () => clearInterval(timeId);
  }, [interval]);

  return { image };
}
