import Introduction from 'src/components/Introduction';
import MoreBtn from 'src/components/MoreBtn';
import Spacer from 'src/components/Spacer';
import { useTranslation } from 'src/hooks/useTransaction';

import style from './index.module.scss';

export function ExtensionWallet() {
  const { t } = useTranslation();
  return (
    <div className={style.wrapper}>
      <Spacer space={227} mSpace={0} />
      <div data-anchor="extensionWallet" />
      <Spacer space={200} mSpace={503} />
      <div className={style.textWrapper}>
        <Introduction titleType="h1" descType="b1" descWidth={689} desc={t('pages.Product.Extension Wallet.Extension Wallet desc')}>
          {t('pages.Product.Extension Wallet.Extension Wallet')}
        </Introduction>
        <Spacer space={60} mSpace={20} />
        <MoreBtn
          text={t('pages.Product.Extension Wallet.Get Started')}
          link="https://chrome.google.com/webstore/detail/cosmostation/fpkhgmpbidmiogeglndfbkegfdlnajnf?utm_source=chrome-ntp-icon"
        />
      </div>
      <Spacer space={376} mSpace={60} />
      <img className={style.img} src={`${process.env.PUBLIC_URL}/assets/product/EXTENSION_WEB.png`} alt="" />
    </div>
  );
}
