import { useIsDesktop } from 'src/hooks/useIsDesktop';

export default function NotFound() {
  const isDesktop = useIsDesktop();
  return (
    <div
      style={
        isDesktop
          ? {
              backgroundImage: `url(${process.env.PUBLIC_URL}/assets/404-img.png)`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              height: '1080px',
              width: '100%',
            }
          : {
              backgroundImage: `url(${process.env.PUBLIC_URL}/assets/404-img_mobile.png)`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              height: '820px',
              width: '100%',
            }
      }
    />
  );
}
