import CheckContext from 'src/components/CheckContext';
import { ContentsBox } from 'src/components/ContentsBox';
import { ContentsFrame } from 'src/components/ContentsFrame';
import Introduction from 'src/components/Introduction';
import PageBackground from 'src/components/PageBackground';
import { useIsDesktop } from 'src/hooks/useIsDesktop';
import { useTranslation } from 'src/hooks/useTransaction';

import Consult from './components/Consult';
import RnDBox from './components/RnDBox';
import Spacer from '../../components/Spacer/index';

export default function Service() {
  const isDesktop = useIsDesktop();
  const { t, language } = useTranslation();
  return (
    <PageBackground page="Service">
      <ContentsFrame type="main">
        <Spacer space={280} mSpace={100} />
        <div data-anchor="consulting" />
        <Introduction titleType="h1" descType="b1" descWidth="770px" color="black" desc={t('pages.Service.index.web3 desc')} gap={30} mGap={14}>
          {isDesktop ? (
            <>
              <strong data-color="red">{t('pages.Service.index.Web3 Infrastructure')} </strong>
              {t('pages.Service.index.for')}
              <br />
              {t('pages.Service.index.Institutions, Protocols,')}
              <br />
              {t('pages.Service.index.Governments, and Investors')}
            </>
          ) : (
            <>
              <strong data-color="red">{t('pages.Service.index.Web3 Infrastructure')}</strong>
              <br />
              {t('pages.Service.index.for Institutions,')}
              <br />
              {t('pages.Service.index.Protocols,')}
              <br />
              {t('pages.Service.index.Governments,')}
              <br />
              {t('pages.Service.index.and Investors')}
            </>
          )}
        </Introduction>
        <Spacer space={140} mSpace={60} />
        <ContentsBox
          rightPadding={64}
          bottomPadding={80}
          intro={
            <Introduction color="black" titleType="h2">
              {language === 'ko' ? (
                <>
                  {t('pages.Service.index.Fully customizable')}
                  <br />
                  {t('pages.Service.index.Consulting Service')}
                </>
              ) : (
                <>{t('pages.Service.index.Fully customizable Consulting Service')}</>
              )}
            </Introduction>
          }
          context={
            <>
              <Spacer space={80} mSpace={40} />
              <CheckContext
                maxWidth="900px"
                maxMWidth="none"
                rPadding="1px"
                contents={[
                  {
                    title: t('pages.Service.index.Architectural Design for Blockchain-based Projects'),
                    contents: t('pages.Service.index.Architectural Design desc'),
                  },
                  {
                    title: t('pages.Service.index.Business Development and Partnerships'),
                    contents: t('pages.Service.index.business desc'),
                  },
                ]}
              />
            </>
          }
        />
        <Spacer space={240} mSpace={60} />
        <Introduction
          titleType="h2"
          descType="b3600"
          descWidth="1025px"
          color="black"
          desc={t('pages.Service.index.case of application desc')}
          mDesc={
            <>
              {t('pages.Service.index.case of application desc1')}
              <br />
              {t('pages.Service.index.case of application desc2')}
            </>
          }
          mGap={14}
        >
          {t('pages.Service.index.Case of application')}
        </Introduction>
        <Spacer space={100} mSpace={40} />
        <Consult />
        <Spacer space={240} mSpace={60} />
        <div data-anchor="rnd" />
        <Introduction titleType="h1" descType="b1" descWidth="1000px" color="black" desc={t('pages.Service.index.R&D desc')} gap={30} mGap={14}>
          {t('pages.Service.index.R&D')}
        </Introduction>
        <Spacer space={140} mSpace={60} />
        <ContentsBox
          rightPadding={64}
          bottomPadding={76}
          intro={
            <Introduction titleType="h2" color="black">
              {t('pages.Service.index.Research & Development')}
              {/* br 넣어랑 */}
            </Introduction>
          }
          context={
            <>
              <Spacer space={80} mSpace={40} />
              <RnDBox
                contents={[
                  {
                    title: t('pages.Service.index.Mintscan'),
                    contents: t('pages.Service.index.Mintscan desc'),
                  },
                  {
                    title: t('pages.Service.index.Mobile Wallet'),
                    contents: t('pages.Service.index.Mobile Wallet desc'),
                  },
                  {
                    title: t('pages.Service.index.Wallet Extension'),
                    contents: t('pages.Service.index.Wallet Extension desc'),
                  },
                  {
                    title: t('pages.Service.index.Defistation'),
                    contents: t('pages.Service.index.Defistation desc'),
                  },
                  {
                    title: t('pages.Service.index.Dapp'),
                    contents: t('pages.Service.index.Dapp desc'),
                  },
                ]}
              />
            </>
          }
        />
        <Spacer space={300} mSpace={100} />
      </ContentsFrame>
    </PageBackground>
  );
}
