import { ContentsBox } from 'src/components/ContentsBox';
import { ContentsFrame } from 'src/components/ContentsFrame';
import Context from 'src/components/Context';
import { CustomFlex } from 'src/components/CustomFlex';
import Introduction from 'src/components/Introduction';
import Spacer from 'src/components/Spacer';
import { useTranslation } from 'src/hooks/useTransaction';

import { ExtensionWallet } from './components/ExtensionWallet';
import { Mintscan } from './components/Mintscan';
import { MobileWallet } from './components/MobileWallet';
import { SmallContextFrame } from './components/SmallContextFrame';
import { Spacestation } from './components/Spacestation';

import style from './index.module.scss';

export function Product() {
  const { t, language } = useTranslation();
  return (
    <ContentsFrame type="main">
      <Spacer space={280} mSpace={100} />
      <Introduction titleType="h1" descType="b1" desc={t('pages.Product.index.Blockchain desc')} descWidth="1106px" gap={30} mGap={14}>
        {t('pages.Product.index.Blockchain')} <strong data-color="red">{t('pages.Product.index.Infrastructure')}</strong>
        {` ${t('pages.Product.index.and')}`}
        <br /> <strong data-color="red">{t('pages.Product.index.End-User')}</strong> {t('pages.Product.index.Product Suite')}
      </Introduction>

      <Spacer space={140} mSpace={42} />
      <div data-anchor="mintscanExplorer" />
      <Mintscan />

      <Spacer space={0} mSpace={86} />
      <ContentsBox
        rightPadding={84}
        bottomPadding={77}
        intro={
          <Introduction titleType="h2" gap={30}>
            {t('pages.Product.index.Block Explorer')}
          </Introduction>
        }
        context={
          <CustomFlex>
            <Context
              contents={[
                {
                  icon: `${process.env.PUBLIC_URL}/assets/icon/INTERCHAIN EXPLOER.svg`,
                  title: t('pages.Product.index.Interchain Explorer'),
                  desc: t('pages.Product.index.Interachain desc'),
                  maxWidth: 308,
                },
                {
                  icon: `${process.env.PUBLIC_URL}/assets/icon/MINIMIZED DELAY.svg`,
                  title: t('pages.Product.index.Minimized Delay'),
                  desc: t('pages.Product.index.Minimized desc'),
                  maxWidth: 308,
                },
                {
                  icon: `${process.env.PUBLIC_URL}/assets/icon/ON-CHAIN ANALYTICS.svg`,
                  title: t('pages.Product.index.On-Chain Analytics'),
                  desc: t('pages.Product.index.On-Chain desc'),
                  maxWidth: 308,
                },
              ]}
            />
          </CustomFlex>
        }
      />

      <ExtensionWallet />
      <div className={style.smallContextWrapper}>
        <SmallContextFrame height={446}>
          <Context
            contents={[
              {
                icon: `${process.env.PUBLIC_URL}/assets/icon/CONVENIENT UIUX.svg`,
                title: t('pages.Product.index.Convenient UI/UX'),
                desc: t('pages.Product.index.Convenient desc'),
                maxWidth: 303,
                titleMaxWidth: 134,
                mTitleWidth: language === 'ko' ? 'none' : 78,
              },
            ]}
          />
        </SmallContextFrame>
        <SmallContextFrame height={446}>
          <Context
            contents={[
              {
                icon: `${process.env.PUBLIC_URL}/assets/icon/SAFE&SECURE.svg`,
                title: t('pages.Product.index.High-level Security'),
                desc:
                  language === 'ko' ? (
                    <>
                      {t('pages.Product.index.High-level Security desc1')}
                      {t('pages.Product.index.High-level Security desc2')}
                    </>
                  ) : (
                    <>
                      {t('pages.Product.index.High-level Security desc1')}
                      <br />
                      {t('pages.Product.index.High-level Security desc2')}
                    </>
                  ),
                maxWidth: 273,
                titleMaxWidth: 119,
                mTitleWidth: 86,
                mDescWidth: 220,
              },
            ]}
          />
        </SmallContextFrame>
        <SmallContextFrame height={446}>
          <Context
            contents={[
              {
                icon: `${process.env.PUBLIC_URL}/assets/icon/ETHEREUM AND BEYOND.svg`,
                title: t('pages.Product.index.Multichain Wallet Extension'),
                desc: t('pages.Product.index.Multichain Wallet Extension desc'),
                maxWidth: 275,
                titleMaxWidth: language === 'ko' ? 'none' : 200,
                mTitleWidth: language === 'ko' ? 'none' : 140,
              },
            ]}
          />
        </SmallContextFrame>
      </div>

      <Spacer space={240} mSpace={130} />
      <div data-anchor="mobileWallet" />
      <MobileWallet />
      <Spacer space={57} mSpace={60} />
      <ContentsBox
        intro={
          <Introduction titleType="h2" mTitleWidth={language === 'ko' ? 'initial' : 200}>
            {t('pages.Product.index.One-For-All')} <br />
            {t('pages.Product.index.Gateway to The Interchain')}
          </Introduction>
        }
        context={
          <CustomFlex>
            <Context
              contents={[
                {
                  icon: `${process.env.PUBLIC_URL}/assets/icon/MOBILE CRYPTO WALLET.svg`,
                  title: t('pages.Product.index.Mobile Crypto Wallet'),
                  desc: t('pages.Product.index.Mobile Crypto Wallet desc'),
                  maxWidth: 296,
                },
                {
                  icon: `${process.env.PUBLIC_URL}/assets/icon/ASSET MANAGEMENT.svg`,
                  title: t('pages.Product.index.Asset Management'),
                  desc: t('pages.Product.index.Asset Managment desc'),
                  maxWidth: 310,
                },
                {
                  icon: `${process.env.PUBLIC_URL}/assets/icon/SAFE&SECURE.svg`,
                  title: t('pages.Product.index.Safe & Secure'),
                  desc: t('pages.Product.index.Safe & Secure desc'),
                  maxWidth: 314,
                },
              ]}
            />
          </CustomFlex>
        }
      />
      <Spacestation />

      <div className={style.smallContextWrapper} style={{ display: 'none' }}>
        <SmallContextFrame height={440}>
          <Context
            contents={[
              {
                icon: `${process.env.PUBLIC_URL}/assets/icon/CONVENIENT UIUX.svg`,
                title: t('pages.Product.index.Convenient UI/UX'),
                desc: t('pages.Product.index.Convenient UI/UX desc'),
                maxWidth: 264,
                titleMaxWidth: language === 'ko' ? 'none' : 134,
                mTitleWidth: language === 'ko' ? 'none' : 80,
              },
            ]}
          />
        </SmallContextFrame>
        <SmallContextFrame height={440}>
          <Context
            contents={[
              {
                icon: `${process.env.PUBLIC_URL}/assets/icon/FAST&LOW TRANSACTION.svg`,
                title: t('pages.Product.index.Fast & Low Transaction Fee'),
                desc: t('pages.Product.index.Fast & Low Transaction Fee desc'),
                maxWidth: 275,
                titleMaxWidth: language === 'ko' ? 'none' : 184,
                mTitleWidth: language === 'ko' ? 'none' : 130,
              },
            ]}
          />
        </SmallContextFrame>
        <SmallContextFrame height={440}>
          <Context
            contents={[
              {
                icon: `${process.env.PUBLIC_URL}/assets/icon/ETHEREUM AND BEYOND.svg`,
                title: t('pages.Product.index.Ethereum and Beyond'),
                desc: t('pages.Product.index.Ethereum and Beyond desc'),
                maxWidth: 259,
                titleMaxWidth: language === 'ko' ? 'none' : 136,
                mTitleWidth: language === 'ko' ? 'none' : 99,
              },
            ]}
          />
        </SmallContextFrame>
      </div>

      <Spacer space={300} mSpace={100} />
    </ContentsFrame>
  );
}
