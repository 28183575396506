import { useTranslation } from 'src/hooks/useTransaction';

import { contactDats } from './datas';

import style from './index.module.scss';

export default function ContactUs() {
  const { t } = useTranslation();
  return (
    <div className={style.contactContainer}>
      <div className={style.contact}>{t('pages.About.ContactUs.Contact Us')}</div>
      <div className={style.infoArea}>
        <div className={style.title}>{t('pages.About.ContactUs.Address')}</div>
        <div className={style.content}>{t('pages.About.ContactUs.Nonhyeon-ro, Gangnam-gu, Seoul, Republic of Korea')}</div>
        <div className={style.title}>{t('pages.About.ContactUs.email')}</div>
        <div className={style.content}>{t('pages.About.ContactUs.help@stamper.network')}</div>
        <div className={style.title}>{t('pages.About.ContactUs.Community')}</div>

        <div className={style.iconContainer}>
          {contactDats.map((item, idx) => (
            <a href={item.href} className={style.icon} key={idx} target="_blank" rel="noreferrer">
              {item.img}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}
