import { useEffect, useState } from 'react';

export const useReactHash = () => {
  const [path, setPath] = useState(window.location.hash);
  const listenToPopstate = () => {
    const hashName = window.location.hash;
    setPath(hashName);
  };
  useEffect(() => {
    window.addEventListener('popstate', listenToPopstate);
    return () => {
      window.removeEventListener('popstate', listenToPopstate);
    };
  }, []);
  return path;
};
