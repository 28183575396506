import React from 'react';

import { Box } from 'src/components/Box/index';

import style from './index.module.scss';

type ContentsBoxProps = {
  intro?: React.ReactNode;
  context?: React.ReactNode;
  bgImg?: React.ReactNode;

  topPadding?: number;
  mTopPadding?: number;
  rightPadding?: number;
  bottomPadding?: number;
  mBottomPadding?: number;
};

export function ContentsBox({
  intro,
  context,
  bgImg,
  topPadding = 64,
  mTopPadding,
  rightPadding = 64,
  bottomPadding = 80,
  mBottomPadding = 40,
}: ContentsBoxProps) {
  return (
    <Box radius={50} mRadius={25}>
      <div
        className={style.box}
        style={{
          paddingTop: topPadding,
          paddingRight: rightPadding,
          paddingBottom: bottomPadding,
        }}
        data-mbpadding={mBottomPadding}
        data-mtpadding={mTopPadding}
      >
        {intro && <div className={style.intro}>{intro}</div>}
        {context && <div className={style.context}>{context}</div>}
        {bgImg}
      </div>
    </Box>
  );
}
