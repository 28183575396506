export type PortfolioCardProps = {
  extention?: string;
  company: string;
  desc: string;
  href: string;
};

export const portfolioCards: PortfolioCardProps[] = [
  {
    company: 'Cosmos',
    desc: 'pages.Ventures.PortfolioCard.Cosmos desc',
    href: 'https://cosmos.network/',
  },
  {
    company: 'Kava',
    desc: 'pages.Ventures.PortfolioCard.Kava desc',
    href: 'https://www.kava.io/',
  },
  {
    company: 'Neutron',
    desc: 'pages.Ventures.PortfolioCard.Neutron desc',
    href: 'https://neutron.org/',
  },
  {
    company: 'Celestia',
    desc: 'pages.Ventures.PortfolioCard.Celestia desc',
    href: 'https://celestia.org/',
  },
  {
    company: 'Ethereum',
    desc: 'pages.Ventures.PortfolioCard.Ethereum desc',
    href: 'https://ethereum.org/en/',
  },
  {
    company: 'Obol',
    desc: 'pages.Ventures.PortfolioCard.Obol desc',
    href: 'https://obol.tech/',
  },
  {
    company: 'Archway',
    desc: 'pages.Ventures.PortfolioCard.Archway desc',
    href: 'https://archway.io/',
  },
  {
    company: 'EtherFi',
    desc: 'pages.Ventures.PortfolioCard.EtherFi desc',
    href: 'https://ether.fi/',
  },
  {
    company: 'Axelar',
    desc: 'pages.Ventures.PortfolioCard.Axelar desc',
    href: 'https://axelar.network/',
  },
  {
    company: 'Polymer Labs',
    desc: 'pages.Ventures.PortfolioCard.Polymer Labs desc',
    href: 'https://www.polymerlabs.org/',
  },
  {
    company: 'Dfinity',
    desc: 'pages.Ventures.PortfolioCard.Dfinity Desc',
    href: 'https://dfinity.org/',
  },
  {
    company: 'Quasar',
    desc: 'pages.Ventures.PortfolioCard.Quasar desc',
    href: 'https://www.quasar.fi/',
  },
  {
    company: 'Stride',
    desc: 'pages.Ventures.PortfolioCard.Stride desc',
    href: 'https://stride.zone/',
  },
  {
    company: 'Nibiru',
    desc: 'pages.Ventures.PortfolioCard.Nibiru desc',
    href: 'https://nibiru.fi/marketing/',
  },
  {
    company: 'Gravity Bridge',
    desc: 'pages.Ventures.PortfolioCard.Gravity Bridge desc',
    href: 'https://www.gravitybridge.net/',
  },
  {
    company: 'Althea',
    desc: 'pages.Ventures.PortfolioCard.Althea desc',
    href: 'https://www.althea.net/',
  },
  {
    company: 'Osmosis',
    desc: 'pages.Ventures.PortfolioCard.Osmosis desc',
    href: 'https://osmosis.zone/',
  },
  {
    company: 'Iris',
    desc: 'pages.Ventures.PortfolioCard.Iris desc',
    href: 'https://www.irisnet.org/',
  },
  {
    company: 'Persistence',
    desc: 'pages.Ventures.PortfolioCard.Persistence desc',
    href: 'https://persistence.one/',
  },
  {
    company: 'Stargaze',
    desc: 'pages.Ventures.PortfolioCard.Stargaze desc',
    href: 'https://www.stargaze.zone/',
  },
  {
    company: 'Umee',
    desc: 'pages.Ventures.PortfolioCard.Umee Desc',
    href: 'https://www.umee.cc/',
  },
  {
    company: 'Passage',
    desc: 'pages.Ventures.PortfolioCard.Passage desc',
    href: 'https://passage3d.com/',
  },
  {
    company: 'Evmos',
    desc: 'pages.Ventures.PortfolioCard.Evmos desc',
    href: 'https://evmos.org/',
  },
];
