import { iconDatas } from './data';

import style from './index.module.scss';

export function ContactItem({ isNav = false }: { isNav?: boolean }) {
  return (
    <div className={style.wrapper}>
      {iconDatas.map((item, idx) => (
        <a data-nav={isNav} className={style.logo} href={item.href} key={idx} target="_blank" rel="noreferrer">
          {item.img}
        </a>
      ))}
    </div>
  );
}
