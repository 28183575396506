import axios from 'axios';

export async function getCountData() {
  try {
    return (
      await axios.get<{ createdAt: string; data: { denom: string; staking_price: string }[] }>(
        'https://serve.dev-mintscan.com/__prod_static/etc/cosmostaion/stats.json',
      )
    ).data.data;
  } catch {
    throw new Error('에러');
  }
}
