import { useEffect, useState } from 'react';
import { t } from 'i18next';

import CountElement from 'src/components/CountElement';

import { useCountUp } from '../../../../hooks/useCountUp';
import { useGetStackingValue } from '../../../../hooks/useGetStackingValue';

import style from './index.module.scss';

export default function MiniDataBox() {
  const [num, setString] = useState(0);

  const numString = useCountUp(0, num, 0.2);

  const { count } = useGetStackingValue();

  useEffect(() => {
    setString(count);
  }, [count]);

  return (
    <div className={style.miniDataArea}>
      <div className={style.countBox}>
        <CountElement
          title={t('pages.Validator.MiniDataBox.Total asset staked')}
          titleType="b0"
          mTitleType="b2-700"
          leftSymbol="$"
          value={numString}
          color="black"
        />
      </div>
      <div className={style.countBox}>
        <CountElement title={t('pages.Validator.MiniDataBox.Total networks')} titleType="b0" mTitleType="b2-700" value={50} rightSymbol="+" color="black" />
      </div>
      <div className={style.countBox}>
        <CountElement title={t('pages.Validator.MiniDataBox.Uptime')} titleType="b0" mTitleType="b2-700" value={99.9} rightSymbol="%" color="black" />
      </div>
    </div>
  );
}
