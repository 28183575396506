export const PATH = {
  ROOT: '/',
  MAIN: '/main',
  VALIDATOR: '/validator',
  PRODUCT: '/product',
  SERVICE: '/service',
  VENTRUES: '/ventures',
  ABOUT: '/about',
  TEST: '/test',
} as const;
