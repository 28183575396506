import { useEffect, useState } from 'react';
import _ from 'lodash';

import { getCountData } from 'src/utils/axios';

export function useGetStackingValue() {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const asynGetCount = async () => {
      const stackingData = await getCountData();

      const sumValue = _.sumBy(stackingData, (item) => Number(item.staking_price));
      setCount(sumValue);
    };
    asynGetCount().catch((e) => {
      throw e;
    });
  }, []);
  return { count };
}
