import style from './index.module.scss';

interface MoreBtnProps {
  text: string;
  color?: string;
  link: string;
  isInner?: boolean;
}

export default function MoreBtn({ text, color = 'black', link, isInner = false }: MoreBtnProps) {
  return (
    <a className={style.moreBtn} href={link} target={isInner ? '' : '_blank'} rel="noreferrer">
      <div className={style.text} data-type={color}>
        {text}
      </div>
      <div className={style.img}>
        <svg className={style.arrowIcon} width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.7279 0.999907C20.9468 0.218858 19.6805 0.218858 18.8995 0.999907C18.1184 1.78096 18.1184 3.04729 18.8995 3.82833L25.0713 10.0002L2.10034 10.0002C0.995774 10.0002 0.100342 10.8956 0.100344 12.0002C0.100344 13.1048 0.995775 14.0002 2.10034 14.0002L25.0712 14.0002L18.8995 20.172C18.1184 20.953 18.1184 22.2193 18.8995 23.0004C19.6805 23.7814 20.9468 23.7814 21.7279 23.0004L31.3139 13.4144C32.095 12.6333 32.095 11.367 31.3139 10.5859L21.7279 0.999907Z"
          />
        </svg>
      </div>
    </a>
  );
}
