import { useTranslation } from 'react-i18next';

import { consultingData } from './datas';
import Card from '../../../../components/Card/index';

import style from './index.module.scss';

export default function Consult() {
  const { t } = useTranslation();
  return (
    <div className={style.ConsultConatiner}>
      {consultingData.map((item) => (
        <div className={style.card} key={item}>
          <Card
            radius={50}
            mRadius={20}
            alignment="end"
            width={120}
            mWidth={60}
            icon={`${process.env.PUBLIC_URL}/assets/consulting/${item}.png`}
            content={<div className={style.company}>{t(`pages.Service.Consult.${item}`)}</div>}
          />
        </div>
      ))}
    </div>
  );
}
