import { Box } from 'src/components/Box';

import style from './index.module.scss';

interface SmallContextFrameProps {
  children: React.ReactNode;
  height?: number;
}

export function SmallContextFrame({ children, height = 440 }: SmallContextFrameProps) {
  return (
    <div className={style.boxFrame} data-height={height}>
      <Box>
        <div className={style.content}>{children}</div>
      </Box>
    </div>
  );
}
