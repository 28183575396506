import Certificate from 'src/components/Certification';
import { ContentsBox } from 'src/components/ContentsBox/index';
import { ContentsFrame } from 'src/components/ContentsFrame';
import Context from 'src/components/Context';
import { CustomFlex } from 'src/components/CustomFlex';
import Introduction from 'src/components/Introduction';
import PartnerCard from 'src/components/PartnerCard';
import Spacer from 'src/components/Spacer';
import { useTranslation } from 'src/hooks/useTransaction';

import DataBox from './components/DataBox';
import IntroBox from './components/IntroBox';
import VideoBox from './components/VideoBox';

export function Main() {
  const { t } = useTranslation();
  return (
    <>
      <ContentsFrame type="video">
        <VideoBox />
      </ContentsFrame>
      <ContentsFrame type="main">
        <Spacer space={240} mSpace={60} />
        <ContentsBox
          intro={
            <Introduction titleType="h2" descType="b3600" desc={t('pages.Main.index.SuperCharge desc')} descWidth="612px">
              <strong data-color="red">{t('pages.Main.index.Supercharge')}</strong>
              <br />
              {t('pages.Main.index.Your Blockchain Journey')}
            </Introduction>
          }
          context={
            <CustomFlex is2x2>
              <Context
                contents={[
                  {
                    icon: `${process.env.PUBLIC_URL}assets/icon/VALIDATOR.svg`,
                    title: t('pages.Main.index.Validator'),
                    desc: t('pages.Main.index.Validator desc'),
                    maxWidth: 520,
                  },
                  {
                    icon: `${process.env.PUBLIC_URL}assets/icon/PRODUCTS.svg`,
                    title: t('pages.Main.index.Products'),
                    desc: t('pages.Main.index.Products desc'),
                    maxWidth: 520,
                  },
                  {
                    icon: `${process.env.PUBLIC_URL}assets/icon/SERVICES.svg`,
                    title: t('pages.Main.index.Services'),
                    desc: t('pages.Main.index.Services desc'),
                    maxWidth: 520,
                  },
                  {
                    icon: `${process.env.PUBLIC_URL}assets/icon/VENTURES.svg`,
                    title: t('pages.Main.index.Ventures'),
                    desc: t('pages.Main.index.Ventures desc'),
                    maxWidth: 520,
                    descMaxWidth: 477,
                  },
                ]}
              />
            </CustomFlex>
          }
        />
        <Spacer space={200} mSpace={60} />
        <DataBox />
        <Spacer space={40} mSpace={20} />
        <IntroBox />
        <Spacer space={200} mSpace={60} />
        <PartnerCard />
        <Spacer space={200} mSpace={50} />
        <Certificate />
        <Spacer space={200} mSpace={100} />
      </ContentsFrame>
    </>
  );
}
