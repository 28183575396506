/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { useState } from 'react';

import Card from 'src/components/Card';
import { useIsDesktop } from 'src/hooks/useIsDesktop';

import type { PortfolioCardProps } from './datas';
import { portfolioCards } from './datas';
import { Box } from '../../../../components/Box/index';
import MoreBtn from '../../../../components/MoreBtn/index';
import { useTranslation } from '../../../../hooks/useTransaction';

import style from './index.module.scss';

export default function PortfolioCard() {
  const isDesktop = useIsDesktop();
  const [isHover, setIsHover] = useState('none');
  const { t } = useTranslation();

  return (
    <div data-anchor="portfolioCard">
      <div className={style.title}>{t('pages.Ventures.index.Portfolio')}</div>
      <div className={style.cardContainer}>
        {portfolioCards.map((item: PortfolioCardProps) => (
          <div
            className={style.card}
            key={item.company}
            onMouseEnter={() => {
              setIsHover(item.company);
            }}
            onMouseLeave={() => {
              setIsHover('none');
            }}
          >
            {isDesktop && isHover === item.company ? (
              <Box radius={50} mRadius={20} color="gray">
                <div className={style.companyCard}>
                  <div className={style.companyInfo}>
                    <div className={style.company}>{item.company}</div>
                    <div className={style.desc}>{t(item.desc)}</div>
                  </div>
                  <MoreBtn text={t('pages.Ventures.index.Show more')} link={item.href} />
                </div>
              </Box>
            ) : (
              <Card
                href={item.href}
                color="white"
                mWidth={130}
                icon={`${process.env.PUBLIC_URL}/assets/portfolio/${item.company}.${item.extention ? item.extention : 'png'}`}
                radius={50}
                mRadius={20}
                width={130}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
