import { useIsDesktop } from 'src/hooks/useIsDesktop';

import style from './index.module.scss';

type ContentsProps = {
  title: React.ReactNode;
  contents: string;
};
type CheckContextProps = {
  contents: ContentsProps[];
  maxWidth: string;
  maxMWidth?: string;
  rPadding?: string;
};
export default function CheckContext({ contents, maxWidth, rPadding = '0px', maxMWidth = maxWidth }: CheckContextProps) {
  const isDesktop = useIsDesktop('600px');
  const radius = isDesktop ? 18 : 12;
  return (
    <div className={style.checkContainer} style={{ paddingRight: rPadding }}>
      {contents.map((item) => (
        <div
          className={style.checkContext}
          key={item.contents}
          style={{
            maxWidth: isDesktop ? maxWidth : maxMWidth,
          }}
        >
          <div className={style.topWrapper}>
            <svg
              className={style.check}
              width={radius * 2}
              height={radius * 2}
              viewBox={`0 0 ${radius * 2} ${radius * 2}`}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx={radius} cy={radius} r={radius} fill="#DB2222" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d={
                  isDesktop
                    ? 'M26.4729 14.7731L15.6001 25.6459L9.52734 19.5731L12.0729 17.0275L15.6001 20.5547L23.9273 12.2275L26.4729 14.7731Z'
                    : 'M17.6486 9.84842L10.4001 17.0969L6.35156 13.0484L8.04862 11.3514L10.4001 13.7028L15.9516 8.15137L17.6486 9.84842Z'
                }
                fill="white"
              />
            </svg>
            <div className={style.title}>{item.title}</div>
          </div>
          <div className={style.contents}>{item.contents}</div>
        </div>
      ))}
    </div>
  );
}
